<template>
	<div class="data-content">
		<div class="top-box">
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<label class="label">状态</label>
				<el-select class="search-input" size="small" v-model="passFlag" filterable placeholder="请选择">
					<el-option value="" label="全部"></el-option>
					<el-option label="进行中" value="1"></el-option>
					<el-option label="未通过" value="2"></el-option>
					<el-option label="通过" value="3"></el-option>
				</el-select>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="getOnlineList">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			 <el-table
				:data="dataList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				style="width: 100%">
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="courseCode"
					show-overflow-tooltip
					label="课程编号">
				</el-table-column>
				<el-table-column
					prop="courseName"
					show-overflow-tooltip
					label="课程名称">
				</el-table-column>
				<el-table-column
					prop="trainEndTime"
					show-overflow-tooltip
					label="测试有效期限">
					<template slot-scope="scope">
						<span>{{scope.row.trainEndTime.replace(new RegExp('-','g'),'/')}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="userScore"
					show-overflow-tooltip
					label="成绩">
				</el-table-column>
				<el-table-column
					prop="examinTime"
					show-overflow-tooltip
					label="状态">
					<template slot-scope="scope">
						<span v-if="scope.row.passFlag == 1">进行中</span>
						<span v-if="scope.row.passFlag == 2">未通过</span>
						<span v-if="scope.row.passFlag == 3">通过</span>
					</template>
				</el-table-column>
				<el-table-column
					label="操作"
					width="130">
					<template slot-scope="scope">
						<button v-if="scope.row.passFlag == 3" class="btn-blue" @click="handleCE(scope.row)">CE查看</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
			<Certificate ref="ceData"></Certificate>
		</div>
	</div>
</template>
<script>
import Pagination from '@/components/Pagination'
import Certificate from '@/components/Certificate'
export default {
	components: {
		Pagination,
		Certificate
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			pageNo: 1,
			pageSize: 20,
			total: 0,
			searchKey: '',
			passFlag: '',
			dataList: [],
			visible: false
		}
	},
	created() {
		this.getOnlineList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 重置查询条件
		handleReset() {
			this.searchKey = '';
			this.passFlag = '';
			this.pageNo = 1;
			this.getOnlineList();
		},
		// 获取用户列表数据
		getOnlineList() {
			let that = this;
			that.$request.post(
				"getExamScoreList",
				true,
				{
					searchKey: that.searchKey,
					passFlag: that.passFlag,//1发布2撤销
					pageNo: that.pageNo,
					pageSize: that.pageSize
				},
				function (r) {
					if (r.code == "0") {
						that.dataList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleCE(dt) {
			this.$refs.ceData.init(dt);
		},
		handleSizeChange() {
			this.getOnlineList();
		},
		handleCurrentChange() {
			this.getOnlineList();
		},
		handleSearch() {
			this.pageNo = 1;
			this.getOnlineList();
		}
	}
}
</script>
<style lang="scss" scoped>
.block{
	display: block;
}
.data-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>
<style>
	.el-message .user-message{
		margin: 15px;
	}
</style>
